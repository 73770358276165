
import ForestLayer1 from '../images/Forest-Layer-1.png';
import ForestLayer2 from '../images/Forest-Layer-2.png';
import ForestLayer3 from '../images/Forest-Layer-3.png';
import ForestLayer4 from '../images/Forest-Layer-4.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    layer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        animationFillMode: 'forwards',
        animationDuration: '2s'
    }
});

type ParalaxProps = {
    finishAnimation: string
}

export function ParalaxBack({ finishAnimation }: ParalaxProps) {

    const classes = useStyles();

    return <>
        <div
            className={classes.layer}
            style={{
                backgroundImage: `url("${ForestLayer1}")`,
                animationName: `slide1${finishAnimation === 'in' || finishAnimation === 'stay' ? '' : 'Out'}${finishAnimation === 'none' ? 'ff' : ''}`,
                zIndex: -100
            }}></div>
        <div
            className={classes.layer}
            style={{
                backgroundImage: `url("${ForestLayer2}")`,
                animationName: `slide2${finishAnimation === 'in' || finishAnimation === 'stay' ? '' : 'Out'}${finishAnimation === 'none' ? 'ff' : ''}`,
                zIndex: -200
            }}></div>
        <div
            className={classes.layer}
            style={{
                backgroundImage: `url("${ForestLayer3}")`,
                animationName: `slide3${finishAnimation === 'in' || finishAnimation === 'stay' ? '' : 'Out'}${finishAnimation === 'none' ? 'ff' : ''}`,
                zIndex: -300
            }}></div>
        <div
            className={classes.layer}
            style={{
                backgroundImage: `url("${ForestLayer4}")`,
                animationName: `slide4${finishAnimation === 'in' || finishAnimation === 'stay' ? '' : 'Out'}${finishAnimation === 'none' ? 'ff' : ''}`,
                zIndex: -400
            }}></div>
    </>
}