import { Trans } from "react-i18next";

const markup = { italic: <i />, bold: <strong />, underlined: <u />, newline: <br /> };

// https://react.i18next.com/latest/trans-component#usage-with-simple-html-elements-like-less-than-br-greater-than-and-others-v10.4.0
export const TranslationsWithMarkup = (props: {txtkey: string}) => {
    return <Trans
    i18nKey={props.txtkey} // optional -> fallbacks to defaults if not provided
    // defaults="" // optional defaultValue
    // values={{ what: 'world'}}
    components={markup}
/>
}